.steigerung {
  display: flex;
  flex-direction: column;
  background-color: #4fc5fc;
  margin-right: 2rem;
  margin-left: 2rem;
  border-radius: 25px;
  padding: 1rem;
  border: 2px solid #000000;
  margin-top: 2rem;
  width: 80%;
}

.steigerungs-form {
  display: flex;
  flex-direction: column;
}

.steigerungs-form > * {
  margin-bottom: 20px;
}

.steigerungs-input {
  border-radius: 25px;
  border-width: 1px;
  height: 40px;
}

.steigerungs-submit {
  background-color: #0493d6;
  color: #fff;
  font-weight: bold;
  font-size: 28px;
}

.steigerungs-checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.steigerungs-checkbox {
  margin-right: 5px;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50px;
}


.steigerungs-checkbox-label {
  font-size: 1.25rem;
}
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
}

@media only screen and (min-width: 768px) {
.title {
  font-size: 3em;
}
}